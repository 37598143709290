/** @format */
@import '@styles/variables';
@import '@styles/mixin';

.btnCustom {
	@include btnCustom;
}
.formGroup {
	position: $relative;
	margin-top: -5px;
	.pd_5 {
		padding-bottom: 5px;
	}
	.userIcon {
		position: $absolute;
		left: 15px;
		top: 12px;
		.userIconBlock {
			position: $relative;
			width: 22px;
			height: 24px;
			img {
				display: $d-block;
			}
			&.flag {
				top: 20px;
			}
		}
	}
	input {
		padding-left: 45px !important;
	}
}
.customCountryGroup {
	position: $relative;
	margin-bottom: 0;
	.selectCountryCustom {
		position: $relative;
		margin-left: 10px;
		margin-top: -5px;
		.chooseCountry {
			width: 96px;
			display: flex;
			position: $relative;
			top: 4px;

			.flagBlock {
				position: $relative;
				width: 96px;
				height: 37px;
				display: inline-block;
				margin-right: 5px;
				img {
					display: $d-block;
				}
				.label {
					display: $d-block;
					margin-top: -1.4rem;
					margin-left: 2rem;
				}
			}
			i {
				color: #a8a8a8;
				font-size: 11px !important;
				margin-top: 3px;
				margin-left: 25px;
				position: $relative;
				margin-left: 0.25rem;
				cursor: $pointer;
			}
		}
		.selectCountry {
			position: $absolute;
			z-index: 99;
			border: 1px solid #dadada;
			background: $white;
			margin-top: $mrg5;
			top: 20px;
			left: -10px;
			max-height: 225px;
			overflow: auto;
			& :hover {
				// background: #1891ff;
				background: $orange;
				color: $white;
			}
			li {
				width: 150px;
				padding: 5px 10px;
				font-size: 10px;
				font-family: $base-font;
				cursor: $pointer;
				.flagBlock {
					position: $relative;
					width: 30px;
					height: 15px;
					display: inline-block;
					margin-right: 5px;
					top: 4px;
					img {
						display: $d-block;
					}
				}
			}
		}
	}
}

@import '@styles/variables';
@import '@styles/mixin';
@import '@styles/fonts';
.footer {
	background-color: $black;
	padding: $pad30 0 $pad20;
	margin-top: $mrg60 + 40;
	.container {
		padding: 0 40px;
	}
	@media screen and (max-width: $custom_md) {
		margin-top: $mrg60;
	}
	.newsletterBlock {
		border-bottom: solid 1px #343434;
		padding-bottom: $pad60;
		margin-bottom: $mrg30 + 5;
		@media screen and (max-width: $custom_sm) {
			padding-bottom: $pad50;
		}
		h3 {
			font-size: $font22 + 6;
			color: $white;
			font-family: $base-font-six;
			font-weight: $font-medium;
			margin-bottom: $mrg15;
			span {
				color: $red;
			}
		}
		p {
			font-size: $font14;
			color: $white;
			padding: 0;
			line-height: 22px;
		}
		.newsletterFooterForm {
			margin-top: 20px;
		}
		.formControl {
			height: 36px;
			line-height: 36px;
			border: none;
			font-size: $font14 + 1;
			padding: 0 $pad15;
			border-radius: 50px;
		}
		.btnCustom {
			display: block;
			font-size: $font14 - 2;
			font-weight: $font-bold;
			border-radius: 17px;
			width: 135px;
			background-color: $white;
			border: solid 1px $white;
			height: 37px;
			line-height: 34px;
			cursor: pointer;
			color: $black;
			&:hover {
				background-color: $black;
				color: $white;
			}
			@media screen and (max-width: $custom_sm) {
				margin-top: $mrg30;
			}
			.top_0 {
				position: $absolute;
				top: 0;
			}
		}
	}
	.footerAboutUs {
		p {
			font-size: $font14 - 2;
			color: #a8a2a2;
			padding: 0;
			line-height: 22px;
		}
		.ftLogo {
			max-width: 310px;
			margin-bottom: $mrg20;
		}
	}
	.lastServiceBox {
		@media screen and (max-width: $custom_sm) {
			margin-left: auto;
		}
		position: $relative;
		.countrySelectionBox {
			position: $absolute;
			right: 45px;
			top: 0;
		}
	}

	.footerLinks {
		@media screen and (max-width: $custom_sm) {
			margin: 0 0 $mrg15;
		}
		h5 {
			font-size: $font16 + 1;
			color: $white;
			font-family: $base-font-six;
			margin: 0 0 $mrg15 + 2;
			padding: 0;
			span {
				color: $red;
			}
		}
		&.socialLinks {
			margin-top: $mrg20;
			@media screen and (max-width: $custom_sm) {
				margin-bottom: $mrg20;
			}
			h5 {
				font-family: $base-font;
				font-size: $font14 - 1;
				font-weight: $font-medium;
				margin: 0 0 $mrg5;
				@media screen and (max-width: $custom_sm) {
					margin: 0 0 $mrg10;
				}
			}
			ul {
				li {
					margin-right: $mrg15;
					a {
						font-size: $font22 + 2;
						color: $white;
					}
				}
			}
		}
		ul {
			li {
				padding-bottom: $pad5;
				&.bloghd {
					padding-bottom: $pad25;
					a {
						font-size: $font14;
						color: $white;
						font-weight: $font-bold;
						span {
							color: $red;
						}
					}
				}
				a {
					font-size: $font14 - 2;
					color: #a8a2a2;
					&:hover {
						color: $white;
					}
				}
			}
		}
	}
	p {
		font-size: $font14;
		color: $white;
		padding: 0;
		font-weight: 400;
		line-height: 100%;
	}
	.bottomFooter {
		padding-top: $pad25;
		@media screen and (max-width: $custom_sm) {
			text-align: center;
		}
		p {
			font-size: $font14 - 1;
			color: #7f7f7f;
			@media screen and (max-width: $custom_sm) {
				padding-bottom: $pad15;
			}
		}
		ul {
			@media screen and (max-width: $custom_sm) {
				justify-content: center;
			}
			li {
				font-size: $font22 + 4;
				color: #7f7f7f;
				margin-left: $mrg5;
			}
		}
	}

	.openSubNav {
		position: $relative;
		background: #fff;
		max-width: 150px;
		left: 0;
		top: 15px;
		z-index: 9;
		padding: 0;
		border-radius: 3px;
		display: $d-none;
		box-shadow: 0 0 30px 0 #ccc;
		&::before {
			position: absolute;
			content: '';
			left: 20px;
			top: -12px;
			border-left: 11px solid transparent;
			border-right: 11px solid transparent;
			border-bottom: 13px solid #fff;
			z-index: -1;
		}
		li {
			margin: 0;
			display: block;
			line-height: 1;
			padding: 0 !important;
			height: auto;
			border-bottom: 0;
			span {
				color: $black !important;
				letter-spacing: 1px;
				padding: 15px 19px;
				font-size: $font14 - 3;
				display: $d-block;
				font-family: $base-font;
				font-weight: $font-medium;
				line-height: 1;
				&:hover {
					background-color: $black;
					color: $white !important;
				}
			}
		}
	}

	.outerSubNav {
		color: $white;
		font-weight: 400;
		&:hover {
			.openSubNav {
				display: $d-block;
			}
		}
	}
}

/** @format */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

@font-face {
	font-family: 'proxima_novaregular';
	src: url('#{$webfonts-path}/mark_simonson_-_proxima_nova_regular.woff2')
			format('woff2'),
		url('#{$webfonts-path}/mark_simonson_-_proxima_nova_regular.woff')
			format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'proxima_novabold';
	src: url('#{$webfonts-path}/mark_simonson_-_proxima_nova_bold.woff2')
			format('woff2'),
		url('#{$webfonts-path}/mark_simonson_-_proxima_nova_bold.woff')
			format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'proxima_novasemibold';
	src: url('#{$webfonts-path}/mark_simonson_-_proxima_nova_semibold.woff2')
			format('woff2'),
		url('#{$webfonts-path}/mark_simonson_-_proxima_nova_semibold.woff')
			format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'tt_commons_boldbold';
	src: url('#{$webfonts-path}/Poppins-Bold.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'tt_commonsdemibold';
	src: url('#{$webfonts-path}/Poppins-Bold.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'tt_commonsdemibold';
	src: url('#{$webfonts-path}/Poppins-Bold.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'tt_commonsdemibold';
	src: url('#{$webfonts-path}/Poppins-Bold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'tt_commonsdemibold';
	src: url('#{$webfonts-path}/Poppins-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'tt_commonsdemibold';
	src: url('#{$webfonts-path}/Poppins-Bold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'Barlow';
	src: url('#{$webfonts-path}/Barlow-SemiBold.ttf') format('truetype'),
		url('#{$webfonts-path}/Barlow-SemiBold.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Barlow_Bold';
	src: url('#{$webfonts-path}/Barlow-Bold.ttf') format('truetype'),
		url('#{$webfonts-path}/Barlow-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

/* Poppins */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('#{$webfonts-path}/Poppins-Light.ttf') format('truetype');
}
/* Poppins */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('#{$webfonts-path}/Poppins-Regular.ttf') format('truetype');
}
/* Poppins */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('#{$webfonts-path}/Poppins-Medium.ttf') format('truetype');
}
/* Poppins */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url('#{$webfonts-path}/Poppins-SemiBold.ttf') format('truetype');
}
/* Poppins */
@font-face {
	font-family: 'Poppins-Bold';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('#{$webfonts-path}/Poppins-Bold.ttf') format('truetype');
}
/* Poppins */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url('#{$webfonts-path}/Poppins-Bold.ttf') format('truetype');
}
